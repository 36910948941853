<template>
  <div class="text-center">
    <br /><br /><br /><br />


    <!--  Section 1: Current plans  -->
    <div class="container-center flex space-between">
      <div style="width:48%">
        <p>Right now, I'm content working in the service industry, and pursuing software projects in my free time.  </p>
        <br />
        <!-- <p>I'm also open to a software development position for a good company.</p> -->
        <br />
        <p>I'm interested in both frontend and backend development, and recently, low level hardware design and production.  </p>
        <br />
        <p>I have the most experience in Javascript, both for the web and NodeJS. I'm comfortable with frontend design, HTTPS networking, API design, and databases.</p>
        <br />
        <p>I have experience with several JS frameworks, include VueJS, ReactJS, and lots of smaller npm libraries.   </p>
        <br />
        <p>I also have some experience in C++. </p>
        <br />
      </div>
      <div style="width:48%">
        <div style="width:48%">
        <img src="../assets/page_assets/plans/rooftop-screenshot-1.png" style="padding: 20px; width: 100%;" />
        <br /><br />
        <!-- <img src="../assets/page_assets/plans/rooftop-screenshot-2.png" style="padding: 20px; width: 70%;" />
        <br /><br /> -->
        <img src="../assets/page_assets/plans/recipe-tracker.png" style="padding: 20px; width: 100%;" />
        <br /><br />
        <img src="../assets/page_assets/plans/rooftop-screenshot-3.png" style="padding: 20px; width: 100%;" />
      </div>
      </div>
    </div>

    <br /><br /><br /><br />
    <br /><br /><br /><br />

    <div class="ellipsis"></div>

    <br /><br /><br /><br /><br /><br /><br /><br />



    <!--  Section 2: Long term goals  -->
    <div class="container-center flex space-between" v-if="0">
      <div style="width:48%">
        <p>
          I'm not sure what my long term goal is. 
        </p>
        <br/><br/>
        <!-- <p>
          I'm recently interested in food supply chains, and managing the merchandising and sales of a store.
        </p>
        <br/>
        <p>
          I think small, independently owned grocery stores have a lot to offer communities. 
          Big corporate supermarkets can provide cheap prices, but often fail at connecting with the 
          individual people in their communities. 
        </p>
        <br/><br/>
        <p>
          I'm also interested in making my own store software, including a POS system, inventory tracking, etc. 
        </p>
        <br/><br/> -->
        <p>
          I've been interested in making my own software for a while -- to the right, you can see images from 
          a few of my previous software UI ideas.
        </p>
      </div>
      <!-- <div style="width:48%">
        <p>In my free time, I'm working on a personal project called Rooftop Media.  The project will be hosted at <a href="www.rooftop-media.org" target="_blank">www.rooftop-media.org</a>.</p>
        <br />
        <p>When I get the time to continue this project, I'd like it to be a non-profit software company. </p>
        <br />
        <p>
          The main website would be a webapp, with software to help average people manage practical concerns in their day to day lives. 
        </p>
        <br /><br /><br />
        <p>For example, I'd like to make personal finance tools, recipe and meal tracking tools, household chore and errand management tools, etc.</p>
        <br />
        <p>I'd also like it to have tools to help people make progress on personal projects, like stories, comics, and animations. </p>
        <br />
        <p>The software would be available on our online platform, which could also provide user file storage and messaging.</p>
        <br /><br /><br />

        <p>This project is a longterm goal, that I expect to revise.  For now, I'm looking for more career experience at a good company.</p>
      </div> -->
      <div style="width:48%">
        <img src="../assets/page_assets/plans/rooftop-screenshot-1.png" style="padding: 20px; width: 70%;" />
        <br /><br />
        <!-- <img src="../assets/page_assets/plans/rooftop-screenshot-2.png" style="padding: 20px; width: 70%;" />
        <br /><br /> -->
        <img src="../assets/page_assets/plans/recipe-tracker.png" style="padding: 20px; width: 70%;" />
        <br /><br />
        <img src="../assets/page_assets/plans/rooftop-screenshot-3.png" style="padding: 20px; width: 70%;" />
      </div>
    </div>

    <br /><br /><br /><br />

    <div class="divider-line"></div>

    <br /><br /><br /><br /><br /><br /><br /><br />

    <!--  Section 3: Canyons  -->

  </div>

</template>